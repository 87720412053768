.contact-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #ffd700;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
            animation: fadeIn 0.5s 1s backwards;
        }

        p {
            font-size: 20px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            letter-spacing: 0.3px;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }
    }
}

.contact-form {
    width: 100%;
    margin-top: 20px;
    font-family: 'Coolvetica';

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
        font-family: sans-serif;
    }

    textarea {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
        font-family: sans-serif;
    }

    .flat-button {
        color: #ffd700;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #ffd700;
        float: right;
        border-radius: 4px;
        font-family: sans-serif;
        cursor: pointer;
    }
}

.wave-emoji {
    display: block;
    position: relative;
    left: 60%;
    top: 20%;
    animation: fadeIn 1s 1.8s backwards;
    animation-delay: 2s;
}

@media screen and (max-width: 1200px){
    .wave-emoji {
        overflow-y: visible;
        float: none;
        width: 100%;
        height: 10px;
        margin: 0;
        scale: 50%;
        display: block;
        left: -25px;
    }
}