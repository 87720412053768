.about-page {
    overflow-x: hidden;

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #ffd700;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
            animation: fadeIn 0.5s 1s backwards;
        }

        p {
            font-size: 20px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            letter-spacing: 0.3px;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }
    }

    .arduino-claw {
        display: block;
        position: relative;
        left: 52%;
        top: 15%;
        animation: fadeIn 1s 1.8s backwards;
        animation-delay: 2s;
    }
}

@media screen and (max-width: 1200px) {
    .arduino-claw {
        scale: 175%;
        position: initial;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        margin-top: 40px;
    }
}