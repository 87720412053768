.home-page {
   .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 40%;
      max-height: 90%;
   }

   h1 {
      color: #fff;
      font-size: 53px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
   }

   h2 {
      color: #8d8d8d;
      margin-top: 20px;
      font-weight: 400;
      font-family: sans-serif;
      letter-spacing: 2px;
      animation: fadeIn 1s 1.8s backwards;
   }

   .flat-button {
      color: #ffd700;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #ffd700;
      margin-top: 25px;
      float: left;
      animation: fadeIn 1s 1.8s backwards;
      white-space: nowrap;

      &:hover {
         background: #ffd700;
         color: #333;
      }
   }

   .headshot {
      display: block;
      position: relative;
      left: 55%;
      top: 20%;
      transform: translateY(-7%);
      max-width: 100%;
      height: auto;
      animation: fadeIn 1s 1.8s backwards;
   }
}

@media screen and (max-width: 1200px) {
   .home-page {
      overflow-x: hidden;
      .text-zone {
         position: initial;
         max-height: calc(100vh - 140px);
         width: 100%;
         transform: none;
         padding: 10px;
         box-sizing: border-box;
         height: 100%;
         display: flex;
         flex-direction: column;
         justify-content: center;
         text-align: center;
      }

      h2 {
         font-size: 16px;
      }

      .flat-button {
         float: none;
         display: block;
         margin: 20px auto 0 auto;
         width: 125px;
      }

      .headshot {
         overflow: visible;
         position: relative;
         box-sizing: border-box;
         scale: 750%;
         display: block;
         width: 100px;
         height: auto;
         margin: 0 auto;
         top: 150px;
         margin-top: 30px;
         margin-bottom: 300px;
         left: 63%;
         z-index: 3;
      }
   }
}