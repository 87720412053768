.container {
    width: 100%;
    will-change: contents;
    height: 100vh;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    overflow: auto;
}

@media screen and (max-width: 1200px) {

    .page {
        position: initial;
    }

    .container {
        position: initial;
        height: auto;
        min-height: auto;

        &.about-page,
        &.contact-page,
        &.portfolio-page {
            .text-zone {
                position: initial;
                transform: none;
                width: 100%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
            }
        }
    }
}