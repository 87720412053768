.nav-bar {
    background: #181818;
    width: 100%;
    height: 7.5%;
    position: fixed;
    top: 0;
    z-index: 3;
    min-height: 60px;
    margin-bottom: 50px;

    .logo {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;

        img {
            display: block;
            width: auto;
            transform: scale(0.5);
            translate: -120px;
            height: 100%;
            max-height: 100%;
            margin: 20px;
        }
    }

    nav {
        display: block;
        text-align: center;
        color: #ffffff;
        position: fixed;
        width: 100%;
        margin-top: -60px;

        a {
            margin: 15px;
            font-size: 45px;
            color: #ffffff;
            text-decoration: none;

            i {
                width: 30px;
                height: 30px;
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #ffd700;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 16px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: inline-block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                position: relative;
                padding: 0 15px;

                &::after {
                    content: 'HOME';
                    position: absolute;
                    left: 50%;
                    transform: translateX(-80%);
                    bottom: 30%;
                    /* Adjust this value as needed */
                    width: 30px;
                    /* Set the width to match the icon */
                    text-align: center;
                    /* Center the text horizontally */
                }
            }

        }

        a.about-link {
            position: relative;
            padding: 0 15px;

            /* Adjust the padding around the icon */
            &:after {
                content: 'ABOUT';
                position: absolute;
                left: 50%;
                transform: translateX(-100%);
                bottom: 30%;
                /* Adjust this value as needed */
                width: 30px;
                /* Set the width to match the icon */
                text-align: center;
                /* Center the text horizontally */
            }
        }

        a.portfolio-link {
            position: relative;
            padding: 0 15px;

            /* Adjust the padding around the icon */
            &:after {
                content: 'PROJECTS';
                position: absolute;
                left: 50%;
                transform: translateX(-150%);
                bottom: 30%;
                /* Adjust this value as needed */
                width: 30px;
                /* Set the width to match the icon */
                text-align: center;
            }
        }

        a.contact-link {
            position: relative;
            padding: 0 15px;

            /* Adjust the padding around the icon */
            &:after {
                content: 'CONTACT';
                position: absolute;
                left: 50%;
                transform: translateX(-150%);
                bottom: 30%;
                /* Adjust this value as needed */
                width: 30px;
                /* Set the width to match the icon */
                text-align: left;
            }
        }

        a.active {
            svg {
                color: #ffd700;
            }
        }
    }

    ul {
        position: absolute;
        bottom: -20px;
        width: 90%;
        transform: translateX(100%);
        display: flex;
        padding: 0;
        list-style: none;
        text-align: right;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                margin: 15px;
                display: block;
                font-size: 45px;

                &:hover svg {
                    color: #ffd700;
                }
            }
        }
    }
}

.hamburger-icon,
.close-icon {
    display: none;
}

@media screen and (max-width: 1200px) {
    .nav-bar {
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;

        ul,
        nav {
            display: none;
        }

        nav {
            width: 100%;
            height: 100%;
            background: #181818;
            top: 0;
            left: 0;
            position: fixed;
            z-index: 2;
            margin: 0;

            a {
                display: flex;
                align-items: center;
                margin: 20px 60px;
                font-size: 45px;
                color: #ffffff;
                text-decoration: none;
            }

            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }

            a svg {
                opacity: 1 !important;
            }

            &.mobile-show {
                display: block;
            }
        }

        .hamburger-icon,
        .close-icon {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }

    .logo {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-start;

        img {
            display: block;
            max-height: 100%;
            margin: 0;
            padding-left: 100px;
            scale: 150%;
        }
    }
}